.scrollWrapper{
	
	.scrollToTop {
		display: inline-block;
		width: 30px;
		height: 30px;
		padding: 10px;
		border-radius: 50%;
		border: 5px solid $mainColor2;
		cursor: pointer;
		margin: 0px auto;
		i {
			color: $mainColor2;
			font-size: 0.8rem;
			position: relative;
			top: -12px;
			left: -6px;
		}
	}
	div{
		color: $mainColor;
	}
}

/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToBottom {
	position: absolute;
	bottom: 0px;
	left: calc(50% - 40px);
	width: 80px;
	height: 50px;
	background: grey;
	padding: 10px;
	border-radius: 40px 40px 0px 0px;
	background-color: $mainColor;
	z-index: 4000;
	cursor: pointer;
	i {
		font-size: 1.5em;
		color: white;
		text-align: center;
		width: 100%;
	}
}