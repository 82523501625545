.VacanciesHolder {
	.vacancy__item {
		border-bottom: 1px solid $mainColor;
		padding-bottom:20px;
		&:last-child{
			border-bottom: 0px solid $mainColor;
		}
		h4 {
			padding-top: 20px;
			font-weight: 300;
			
		}
	}
}