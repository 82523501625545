.homepageTwitter {
	position:relative;
	/*float: left;*/
	width:calc(50% - 10px);
	margin-left:20px;
	display: inline-block;

	.twitterLogo {
		display: block;
		width:80px;
		height:80px;
		margin:0 auto 40px auto;
		background-image:url(../images/twitterLogo.svg);
		background-size:contain;
		background-position:center center;
		background-repeat: no-repeat;
	}
	
	.twitterPrev, .twitterNext {
		width:20px;
		height:20px;
		position:absolute;
		top:50%;
		@include transform(translateY(-50%));
		color:$mainColor;
		cursor:pointer;
	}
	.twitterPrev {
		content:'⇦';
		left:-40px;
	}
	.twitterNext {
		content:'⇨';
		right:-40px;
	}
	section {
		max-width:1280px;
		width:100%;
		margin:0 auto;
		padding:20px;
	}
	.homepageTwitterThumb {
		width:120px;
	}
	
}

#tweets {
	position: relative;
	padding:0 20px 0 0;
	height:490px;
	overflow-y:scroll;
	&::-webkit-scrollbar{
		width:2px;
		height:2px;
	}
	&::-webkit-scrollbar-button:start:decrement,
	&::-webkit-scrollbar-button:end:increment{
		display:block;
		height:0;
		background-color:transparent;
	}
	&::-webkit-scrollbar-track-piece{
		background-color:transparent;
		@include border-radius(0px);
	}
	&::-webkit-scrollbar-thumb{
		background-color: rgba(#FFFFFF, 0.5);
		@include border-radius(0px);
	}
	&::-webkit-scrollbar-thumb:vertical{
	}
	&::-webkit-scrollbar-thumb:horizontal{
	}
	&::-webkit-scrollbar-thumb:hover{
		background-color: #FFFFFF;
	}
	.title__block {
		h4 {
			color:#FFFFFF;
			margin:0;
			font-size:35px;
			font-weight:300;
		}
	}
	
		
			
			.tweetItem {
				width: 100%;
				color: $mainColor2;
				padding-bottom: 20px;
				p {
					font-weight: 300;
					color: #FFFFFF;
					line-height: 30px;
					font-size: 18px;
					/*height: 300px;*/
				}
				.twitterThumb {
					width:100%;
				}
			}

			.frame {
				border-top: 2px solid #FFFFFF;
				display:flex;
			}
			.tweetHandle {
				a {
					font-weight: 300;
					color: red;
				}
			}
			.tweetMeta {
				width: 151px;
				padding: 10px 0;
				text-align: left;
				float: left;
				.meta1, .metaRetweet, .metaLike {
					display: inline-block;
					margin-right: 15px;
					svg {
						path {
							fill: #FFFFFF;
							@include transition($t1)
						}
					}
					svg:hover {
						path {
							opacity:0.6;
						}
					}
				}
			}
			.tweetData {
				float: left;
				width: calc(100% - 151px);
				text-align: right;
				/*padding-top: 15px;*/
				text-align: right;
				p {
					color: #FFFFFF;
					display: inline-block;
					margin:10px 0 10px 10px;
					display: inline-block;
					height: auto;
					font-size: 15px;
					span {
						color: #FFFFFF;
					}	
				}
			}
		
	
	.controlNav {
		position: relative;
		.prev, .next {
			position: relative;
			border: 1px solid $mainColor;
			display: inline-block;
			padding: 10px;
			margin: 0;
			width:30px;
			height: 30px;
			line-height: 0px;
			cursor: pointer;
			a {
				width:100%;
				height:100%;
				display:block;
			}
		}
		.next {
			margin-left: 20px;
		}
		.prev:hover, .next:hover {
			background: red;
			svg {
				polygon {
					fill: white;
				}
			}
		}
	}
	.bgs {
		position: absolute;
		display: none;
		width: 100%;
		height: 100%;
		.yellow {
			position: relative;
			background-color: white;
			width: 50%;
			height: 100%;
		}
	}
}
