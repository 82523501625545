.centerColumn {
	.centerLeft {
		.galleryWrapper {
			display: inline-block;
			// border:2px solid $lightGrey;
			// padding:20px;
			position: relative;
			margin:0 20px 30px 0;
			overflow:hidden;
			.galleryDecoration {
				display: inline-block;
				width: 200px;
				height: 200px;
				.galleryImageSm {
					width:200px;
					height:200px;
					border-radius:25px;
					background-size: cover;
					background-position:center center;
				}
			}
			.galleryTitle {
				text-align: center;
				margin:20px 0;
				border-top:1px solid $LinkColor;
				padding-top:20px;
				@include transition($t1);
			}
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				@include transition($t1);
				opacity:0;
			}
			&:hover {
				.galleryTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
			
		}

		.galleryImage {
			display: inline-block;
			border:2px solid $lightGrey;
			padding:20px;
			position: relative;
			margin:0 20px 30px 0;
			overflow:hidden;
			.thumb {
				width:200px;
				height:200px;
				border-radius:25px;
				background-size: cover;
				background-position:center center;
			}
			.galleryImageTitle {
				text-align: center;
				margin:20px 0 0 0;
				border-top:1px solid $LinkColor;
				padding-top:20px;
				@include transition($t1);
			}
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				opacity:0;
				@include transition($t1);
			}
			&:hover {
				.galleryImageTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
		}
	}
}




.gallerysurround{
	width: 200px;
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
	.galleryTitle {
		text-align: center;
		padding: 5px 0px;
	}
}