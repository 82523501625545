.homepageFooter {
	width: 100%;
	min-height: 200px;
	border-top: 10px solid $mainColor;
	background-color: #FFF;
	position: relative;
	clear: both;
	.footerLeft, .footerCenter, .footerRight{
		display: inline-block;
		padding: 10px;
		height: 100px;
		float: left;
		span{
			font-weight: 100;
		}
	}
	.footerLeft{
		background-image: url(../images/Logo.png);
		background-position: 60px 50%;
		background-repeat: no-repeat;
		background-size: contain;
		padding-left: 150px;
		margin-top: 35px;
		width: 40%;
		h2{
			position: relative;
			top: 8px;
			left: 0px;
			font-family: $font-heading;
			margin-bottom: 20px;
			text-transform: none;
		}
		span{
			position: relative;
			top: -10px;
			left: 0px;
		}
	}
	.footerCenter{
		margin-top: 45px;
		width: 30%;
		span{
			display: block;
		}
	}
	.footerRight{
		width: 200px;
		float: right;
		margin-right: 50px;
		text-align: center;
		padding-top: 50px;
		a, p{
			font-size: 0.5rem;
			margin-top: 5px;
            margin-bottom: 0;
		}
	}
}
