
.schoolValues{
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(../images/values.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    position: relative;
    h1{
        color: #FFF;
        &::after{
            background-color: #FFF;
            left: 0%;
        }
    }
    h2{
        color: #FFF;
    }
    .valuesText{
        height: 600px;
        overflow: auto;
        background-color: rgba(198,54,43,0.8);
        color: #FFF;
        min-width: 300px;
        width: 25%;
        margin-left: 12.5%;
        font-weight: 100;
        font-size: 0.9rem;
        padding: 50px;
        .valuesInner{
            h2{
                font-family: $font-heading;
                text-transform: none;
            }
            p{
                color: #FFF;
                padding: 0px 50px;
            }
        }
    }
    .switchermenu{
        position: absolute;
        right: 20px;
        bottom: 20px;
        .menuswitch{
            padding: 5px 15px;
            font-weight: 500;
            font-size: 1.1rem;
            color: #FFF;
            cursor: pointer;
            &:nth-child(even){
                color: $themeColor;
            }
        }
        .valuesselected{
            background-color: $mainColor;
        }
    }
    .hand{
        background-image: url(../images/heros/Communication.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0% 100%;
        display: inline-block;
        position: absolute;
        bottom: 50px;
        left: calc(12.5% - 110px);
        width: 200px;
        height: 400px;
    }
    .handnew{
        background-image: url(../images/Hand.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0% 100%;
        display: inline-block;
        position: absolute;
        top: -150px;
        left: calc(43%);
        width: 300px;
        height: 500px;
    }
}


.newslettersIndex{
    width: 100%;
    background-image: url(../images/newsletters.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    position: relative;
    .newslettersText{
        height: 600px;
        overflow: auto;
        min-width: 300px;
        width: 30%;
        margin-left: 57.5%;
        font-weight: 100;
        font-size: 0.9rem;
        .newslettersHeading{
            background-color: rgba(198,54,43,0.8);
            color: #FFF;
            padding: 50px;
            padding-bottom: 30px;
            h1{
                color: #FFF;
                &::after{
                    background-color: #FFF;
                    left: 0%;
                }
            }
        }
    }
}

.slideOutWrapperR, .slideOutWrapperL{
    position: relative;
    top: 0px;
    left: 0px;
    height: 20px;
    #slideRight, #slideLeft{
        background-color: $themeColor;
        width: 300px;
        padding: 20px;
        display: inline-block;
        position: absolute;
        top: -120px;
        z-index: 1000;
        .quote{
            font-family: serif;
            font-size: 2rem;
            text-align: center;
            color: #FFF;
            &::before{
                font-family: serif;
                content: ' \201C ';
            }
            &::after{
                content: ' \201D ';
            }
        }
        .name{
            text-transform: uppercase;
            text-align: center;
            color: #FFF;
            margin-top: 10px;
        }
    }
    #slideLeft{
        left: -300px;
        top: -60px;
    }
    #slideRight{
        left: 100%;
    }
}