/*
|===============================================================
|	Gallery Template #1
|	Uses a simple square gallery list w/ Fanybox using
| 	background-image in front end to display image.
|	@responsive - stacked on smaller screens 
|===============================================================
*/

.galleryTemplate {
	.galleryImage {
		position: relative;
		display:inline-block;
		text-align:center; 
		.info {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.click, .bg {
				@include transition(all 0.5s);
			}
			.click {
				color: white;
				opacity: 0;
				z-index: 10;
			}
			.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: calc(100% - 5px);
				background-color: $mainColor;
				opacity: 0;
				z-index: 5;
			}
		}
		&:hover {
			.info {
				.bg {
					opacity: 0.8;
				}
				.click {
					opacity: 1;
				}
			}
		}
	}
}


