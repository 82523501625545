/*
|===============================================================
|	Name: Contents Area
|	Desc: Styling used for displaying inner pages of parent page
|===============================================================
*/
.contents_holder {
	a {
		border: none !important;
	}
	width: 100%;
	@include breakpoint(toTablet) {
		text-align: center;
	} 
	.page {
		position: relative;
		display: inline-block;
		width: 200px;
		height: 200px;
		background-color: $mainColor;
		margin-right: 20px;
		margin-bottom: 20px;
		@include breakpoint(toTablet) {
			margin-right: 0;
		} 
		* {
		 color: white;
		}
		.front, .back {
			position: absolute;
			top: 0;
			left: 0;
			width: 200px;
			height: 200px;
			/*@extend .aniall;*/
			p {
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				@include transform($center);
			}
		}
		.back {
			/*background-color: $yellow;*/
			opacity:0;
			p {
				color: #000;
			}
		}
		&:hover {
			.front {
				opacity: 0;
			}
			.back {
				opacity: 1;
			}
		}
	}
}
