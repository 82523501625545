.homepageEventsSliderWrapper, #homepage-events {
	position: relative;
	background-size:cover;
	background-position:center 40%;
	padding:90px 30px 60px 30px;
	background-attachment: fixed;
	width: 100%;
	.title {
		text-align: center;
		h1{
			&::after{
				left: 0%;
			}
		}
	}
	.widgetsWrapper {
		width:100%;
		max-width:1280px;
		margin:0 auto;
		display:flex;
		position: relative;
		z-index: 2000;


		.calendar-widget-table {
			font-family:$font-stack;
			padding-bottom:0;
			thead {
				background-color: transparent;
				th {
					background-color: transparent;
					text-align:left;
					position: relative;
					padding:0 20px 20px 20px;
					.show-month {
						padding:0;
					}
					.prev, .next {
						position: absolute;
						top:0;
						/*@include transform(translateY(-50%));*/
						border:2px solid $LinkColor;
						width:40px;
						height:40px;
						background-position: center center;
						background-size:60% 60%;
						background-repeat: no-repeat;
						
						color:red;
						.inner {
							opacity:0;
						}
						&:hover {
							background-color:$LinkColor;
							border:2px solid $LinkColor;
						}
					}
					.prev {
						right:50px;
						background-image:url(../images/leftBracketBlack.svg);

					}
					.next {
						right:0;
						background-image:url(../images/rightBracketBlack.svg);
					}
				}
			} 
			tbody {
				tr {
					background-color:transparent;
					td {
						background-color:transparent;
						color:#000000;
						border:none;
						font-family: $font-stack;
						padding:15px 0;
					}
				}
			}
		}
	
		.sliderControlLeft, .sliderControlRight {
			#eventsNext, #eventsPrev {
				position: absolute;
				top: 100px;
				display:inline-block;
				width: 30px;
				height: 30px;
				text-align:center;
				border: 5px solid $mainColor2;
				border-radius: 50%;
				z-index: 9999;
				i{
					color: $mainColor2;
					font-size: 0.8rem;
					position: relative;
					top: 0px;
					left: 1px;
				}
				a {
					color: $mainColor2;
					width: 100%;
					height: 100%;
					display: block;
					position: relative;
					top: -20px;
					left: 0px;
				}
			}
			#eventsPrev {
				left:0px;
				i{
					left: -1px;
				}
			}
			#eventsNext {
				right:0px;
			}
		}
		.calendarlink{
			position: absolute;
			width: 100%;
			text-align: center;
			top: 250px;		
			font-style: italic;
			.allEvents {
				font-weight: 700;
				padding: 7px 30px;
				height: 40px;
				display: inline-block;
				color: $LinkColor;
                &::before{
                	content: ' \203A ';
                	color: $mainColor2;
                    font-size: 1.2rem;
                	font-weight: 700;
                	position: relative;
                	left: -5px;
                	top: -1px;
                }
			}
		}
	}
	.slidercontainer{
		width: 1000px;
		margin: 0px auto;
		overflow: hidden;
		#sliderUpcoming {
			width: 100%;
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			section {
				padding-bottom:10px;
				min-width: 250px;
				.sliderContent {
					background-color:$LinkColor;
					margin: 20px;
					font-family: $font-stack;
					height: 200px;
					position: relative;
					.title, .date {
						width: 100%;
					}
					.title {
						color: #FFF;
						width: 100%;
						padding: 10px;
						background-color: $themeColor;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						position: absolute;
						bottom: 20px;
						left: 0px;
					}
					.date {
						text-transform: uppercase;
						display: inline-block;
						font-weight: 100;
						padding: 20px;
						span{
							font-size: 1.5rem;
							line-height: 1.2rem;
							width: 100%;
							display: inline-block;
							color: #FFF;
							font-weight: 100;
							&:nth-child(2){
								font-size: 4rem;
								line-height: 4rem;
								font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
							}
							&:nth-child(3){
								font-size: 1rem;
								line-height: 1rem;
								position: relative;
								top: -4px;
							}
						}

					}
				}
				.eventdownload{
					position: absolute;
					top: 65px;
					right: 20px;
					display: inline-block;
					width: 50px;
					height: 50px;
					background-color: $mainColor2;
					text-align: center;
					a{
						display: inline-block;
						width: 100%;
						height: 100%;
						position: relative;
						top: 14px;
						left: 1px;
						font-size: 3rem;
						font-weight: 500;
						color: $mainColor;
					}
				}
			}
			#eventsInner {
				z-index:1;
				section {
					margin-bottom:60px;
					.eventsContent {
						text-align: justify;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.slidercontainer{
		width: 750px !important;
	}
}

@media only screen and (max-width: 900px) {
	.slidercontainer{
		width: 500px !important;
	}
}

@media only screen and (max-width: 650px) {
	.slidercontainer{
		width: 250px !important;
	}
}
