* {
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none;
	outline: none !important;
	box-sizing: border-box;
		margin:0;
	padding:0;
}

a {
	cursor: pointer;
}

html{	
	width: 100%;
	height: 100%;
}

body{
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
	margin:0;
	padding:0;
}
