header {
	position: absolute;
    height: 500px;
    width: 100%;
	nav.main {
		position:absolute;
		top:0;
		z-index:2;
		width:100%;
        display: flex;
        @include justify-content(space-between);
        background-color: rgba(44,59,107,0.7);
        height: 100px;
        .logoHolder {
            text-align: center;
            display: flex;
            @include align-items(center);
            .headerLogo {
                width: 70px;
                margin-right: 10px;
                margin-left: 20px;
            }
            .schoolName {
                font-size: 45px;
                font-family: $font-heading;
                color: #FFF;
                font-weight: 300;
                position: relative;
                top: 5px;
            }
        }
        .navHolder {
            display: flex;
            @include align-items(flex-end);
            .navInner {
                position:relative;
                text-align: right;
                display: inline-block;
                height: 100%;
                .headerLogo {
                    position: relative;
                    width:200px;
                    display:inline-block;
                }
                .LevelOneMenu {
                    display: inline-block;
                    text-align: center;
                    font-size: 1em;
                    line-height: 4em;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    .LevelOneMenuItem {
                        height: 100%;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        &:hover{
                            .LevelTwoMenu{
                                display: block;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                margin-left: -10px;
                                bottom: 0;
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 10px solid $mainColor2;
                                clear: both;
                            }
                        }
                        .LevelOneMenuLink {
                        	text-transform: uppercase;
                            display: block;
                            position: relative;
                            width: 100%;
                            padding: 40px 10px;
                            text-align: center;
                            color: #FFF;
                            @include transition($t1);
                            font-weight: 300;
                            font-size: 16px;
                            &:hover{
                            	&::after{
                            		content: " ";
                            		background-color: #FFF;
                            		height: 1px;
                            		width: 60%;
                            		display: block;
                            		position: absolute;
                            		bottom: 30px;
                            		left: 20%;
                            	}
                            }
                        }
                        .LevelTwoMenu {
                            font-size: 1em;
                   			line-height: 1em;
                            position: absolute;
                            display: none;
                            left: 50%;
                            min-width: 400px;
                            bottom: 0px;
                            background-color: rgba(255,255,255,0.7);
                            @include transform(translateX(-50%) translateY(100%));
                            border-top: 10px solid $mainColor2;
                            padding: 20px 20px 30px 40px;
                            width: 60%;
                            .LevelTwoMenuItem{  
                                display: block;
                                position: relative;
                                width: 50%;
                                float: left;
                                padding: 3px 0;
                                .LevelTwoMenuLink {
                                    text-align: left;
                                    display: block;
                                    position: relative;
                                    width: 100%;
                                    float: left;
                                    clear: both;
                                    padding: 5px 10px;
                                    color: $mainColor;
                                    font-weight: 300;
                                    &:hover {
                                        font-weight: 600;
                                        &::before{
                                        	content: ' \203A ';
                                        	color: $mainColor2;
                                            font-size: 1.2rem;
                                        	font-weight: 500;
                                        	position: absolute;
                                        	left: -5px;
                                        	top: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .noChildren{
                        &:hover{
                            &:after {
                                border-bottom: 0px solid transparent !important;
                            }
                        }
                    }
                }
                /*
                .WideMenu{
                    position: static;
                    .LevelOneMenuItem{
                        position: static;
                    }
                    .LevelTwoMenu {
                        width: 100%;
                    }
                    .LevelTwoMenuItem{
                        width: 25%;
                        display: block;
                        float: left;
                        clear: none;
                    }
                }
                */
            }
            .searchSection {
                position: relative;
                height: 100%;
                width: 50px;
                #searchIcon {
                	color: #FFF;
                    background-image: url(../images/searchIcon.svg);
                    -webkit-filter: invert(100%);
          			filter: invert(100%);
                    width: 16px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin: 0 auto;
                    height: 100%;
                }
            }
        }
	}
}

.banner {
    overflow: hidden;
    max-height: 100vh;
	.flexslider {
		overflow:hidden;
		height: 100vh;
        border-bottom: 10px solid $mainColor;
		ul {
			overflow:hidden;
			height:100%;
			li {
				overflow:hidden;
				position:relative;
				height:100%;
				.slideBG {
					background-position:center center;
					background-size:cover;
					margin-top: 0 !important;
					height:100%;
				}
			}
		}
	}
    .tagline{
        position: absolute;
        text-align: center;
        width: 100%;
        font-family: $font-heading;
        font-size: 2.4rem;
        bottom: 80px;
        color: #FFF;
        left: 0px;
        z-index: 999;
        text-shadow: 0px 0px 4px #000;
    }
    .banneroverlay{
        position: absolute;
        height: 80%;
        width: auto;
        bottom: 80px;
        left: -180px;
        top: 10%;
        z-index: 99999999;
        img{
            height: 100%;
            width: auto;
        }
    }
}



