.ContactForm .centerColumn .centerLeft{
	width: 0px !important;
	min-width: 0px !important;
}
.centerColumn .centerRight .userform .field .text, .centerColumn .centerRight .userform .field .textarea{
	width: 200%;

}

.checkbox .middleColumn{
	width: 100% !important;
}

fieldset.userformsstep{
	margin-top: 0px !important;
}

.userform input[type=text], .userform input[type=email], .userform textarea{
	background-color: #EFEFEF !important;
	border: 0px solid white !important;
	min-width: 70% !important;
}

.requiredField.checkbox .middleColumn{
	width: 1480px;
}

.leftColumn {
	display: block;
}

@media all and (max-width: 1550px)  {
	.centerColumn .centerRight .userform .field .text, .centerColumn .centerRight .userform .field .textarea{
		//min-width: 1120px;
		//width: 95%;
	}
	.requiredField.checkbox .middleColumn{
		//width: 1180px;
	}
	.schoolName{
		font-size: 30px !important;
	}
	.LevelOneMenuLink{
		padding: 40px 5px !important;
		font-size: 13px !important;
	}
	.translate, .searchSection{
		width: 40px !important;
	}
	.translate{
		margin-right: 10px;
	}
	.headerLogo{
		margin-left: 10px !important;
	}
	.quote{
		font-size: 1.5rem !important;
	}
	.slideOutWrapperR{
		top: 60px !important;
	}
	.footerRight a{
		font-size: 0.5rem !important;
	}
	h1, .tagline {
		font-size: 2rem !important;
	}
	h2 {
		font-size: 1.5rem !important;
	}
	h3 {
		font-size: 1.4rem !important;
	}
	h4 {
		font-size: 1.3rem !important;
	}
	p, ul li, ol li, a, .footerCenter span, #usefullinkstoggle{
		font-size: 0.8rem !important;
	}
	.calendarLink a, .sliderContent .title, .singleUpload{
		font-size: 1rem !important;
	}
	.eventdownload{
		a{
		    font-size: 3rem !important;
		}
	}
	.schoolValues .valuesText{
		width: 30% !important;
		margin-left: 15% !important;
		padding: 40px !important;
		.valuesInner p{
			padding: 0px 40px !important;
		}
	}
}



@media all and (max-width: 1200px)  {
    .banneroverlay{
        left: -280px !important;
    }
	.centerColumn .centerRight .userform .field .text, .centerColumn .centerRight .userform .field .textarea{
		//min-width: 820px;
		//width: 95%;
		width: 250%;
	}
	.requiredField.checkbox .middleColumn{
		width: 880px;
	}
	.switchermenu{
		width: 400px !important;
		.menuswitch{
			display: block;
		}
	}
	.schoolValues .valuesText{
		width: 40% !important;
		margin-left: 10% !important;
		padding: 40px !important;
		.valuesInner p{
			padding: 0px 40px !important;
		}
	}
	.hand{
		left: -30px !important;
	}
	.logoHolder{
		a:nth-child(1){
			width: 80px !important;
		}
		img.headerLogo{
			width: 70px !important;
			height: auto !important;
		}
	}
	.schoolName{
		font-size: 23px !important;
	}
	.LevelOneMenuLink{
		padding: 40px 5px !important;
		font-size: 10px !important;
	}
	.translate, .searchSection{
		width: 40px !important;
	}
	.translate{
		margin-right: 0px;
	}
	.headerLogo{
		margin-left: 10px !important;
	}
	.quote{
		font-size: 1.5rem !important;
	}
	.slideOutWrapperL{
		top: 40px !important;
	}
	.footerLeft{
		width: 50% !important;
	}
	.footerCenter{
		width: 50% !important;
	}
	.footerRight{
		display: block !important;
		width: 100% !important;
		margin-right: 0px !important;
		margin-bottom: 50px !important;
		padding-top: 0px !important;
		a{
			font-size: 0.5rem !important;
		}
	}
	h1, .tagline {
		font-size: 1.6rem !important;
	}
	h2 {
		font-size: 1.4rem !important;
	}
	h3 {
		font-size: 1.3rem !important;
	}
	h4 {
		font-size: 1.2rem !important;
	}
	p, ul li, ol li, a, .footerCenter span, #usefullinkstoggle{
		font-size: 0.8rem !important;
	}
	.calendarLink a, .sliderContent .title, .singleUpload{
		font-size: 1rem !important;
	}
	#welcomeMessageWrapper{
		background-image: url(../images/Lion_red2.png);
		background-position: calc(100% + 85px) 0px;
		background-repeat: no-repeat;
		background-size: contain;
	}
}



@media all and (max-width: 1000px)  {
	.clearLarge {
		width: 100% !important;
		clear: both;
	}
}


@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}



@media all and (max-width: 900px)  {
    .banneroverlay{
        left: -480px !important;
    }
	.centerColumn .centerRight .userform .field .text, .centerColumn .centerRight .userform .field .textarea{
		//min-width: 520px;
		//width: 95%;
	}
	.requiredField.checkbox .middleColumn{
		width: 580px;
	}
	.hasEvent {
		background-position: 2px 2px !important;
	}
	.schoolName{
		position: absolute !important;
		top: 20px !important;
		left: 80px !important;
	}
	.navInner{
		height: 50px !important;
	}
    .LevelOneMenuLink {
        padding: 10px 5px !important;
        &:hover{
        	&::after{
        		content: " ";
        		display: none !important;
        		background-color: #FFF;
        		height: 0px;
        		width: 0%;
        	}
        }
    }
	.translate, .searchSection{
		position: absolute !important;
		top: 0px !important;
		right: 0px !important;
		height: 50px !important;
	}
	.searchSection{
		right: 40px !important;
	}
	#welcomeMessageWrapper{
		background-image: url(../images/Lion_red2.png);
		background-position: calc(100% + 85px) 0px;
		background-repeat: no-repeat;
		background-size: contain;
		#welcomeMessage{
			width: 90% !important;
		}
	}
	.quote{
		font-size: 1.2rem !important;
	}
	.switchermenu{
		width: 210px !important;
	}
	.hand{
		left: 400px !important;
		bottom: 200px !important;
	}
	.handnew{
		display: none !important;
	}
	.schoolValues .valuesText{
		padding: 20px !important;
		margin-left: 5% !important;
		.valuesInner p{
			padding: 0px 20px !important;
		}
	}
	.slideOutWrapperL{
		top: 60px !important;
	}
	.newslettersText{
		margin-left: 40% !important;
	}
	.footerLeft{
		width: 100% !important;
		display: block !important;
		text-align: center !important;
		background-position: 10px 50%;
		padding-left: 100px;
		background-position: calc(50% - 140px) !important;
	}
	.footerCenter{
		width: 100% !important;
		display: block !important;
		text-align: center !important;
		margin-top: 0px !important;
	}
	.footerRight{
		display: block !important;
		width: 100% !important;
		margin-right: 0px !important;
		margin-bottom: 50px !important;
		padding-top: 0px !important;
		a{
			font-size: 0.5rem !important;
		}
	}
}


@media all and (max-width: 750px)  {
	.centerColumn .centerRight .userform .field .text, .centerColumn .centerRight .userform .field .textarea{
		//min-width: 520px;
		//width: 95%;
		min-width: 30% !important;
	}
	.centerColumn {
		.centerLeft {
			display: none !important;
		}
	}
}


@media all and (max-width: 600px)  {
	.hand{
		display: none !important;
	}
	.schoolName{
		position: absolute !important;
		top: 20px !important;
		left: 80px !important;
		width: 160px !important;
		line-height: 2rem !important;
	}
	.navInner{
		height: 50px !important;
	}
    .LevelOneMenuLink {
        padding: 10px 5px !important;
        &:hover{
        	&::after{
        		content: " ";
        		display: none !important;
        		background-color: #FFF;
        		height: 0px;
        		width: 0%;
        	}
        }
    }
	.translate, .searchSection{
		position: absolute !important;
		top: 0px !important;
		right: 0px !important;
		height: 50px !important;
	}
	.searchSection{
		right: 40px !important;
	}
	#welcomeMessageWrapper{
		background-image: url(../images/Lion_red2.png);
		background-position: calc(100% + 85px) 0px;
		background-repeat: no-repeat;
		background-size: contain;
		#welcomeMessage{
			width: 90% !important;
			div p{
				padding-left: 10px;
			}
		}
	}
	.quote{
		font-size: 1.2rem !important;
	}
	.switchermenu{
		display: block !important;
		position: static !important;
		width: 100% !important;
	}
	.schoolValues{
		padding-top: 100px !important;
		.valuesText{
			width: 100% !important;
			padding: 20px !important;
			margin-left: 0% !important;
			height: auto !important;
			.valuesInner p{
				padding: 0px 20px !important;
			}
		}
	}
	#eventsNext, #eventsPrev{
		top: 250px !important;
	}
	.slideOutWrapperL{
		top: 60px !important;
		height: 150px !important;
	}
	.slideOutWrapperR{
		height: 200px !important;
		top: 80px !important;
	}
	.newslettersIndex{
		padding-top: 100px;
		.newslettersText{
			margin-left: 0% !important;
			width: 100% !important;
		}
	}
	.footerLeft{
		width: 300px !important;
		margin: 0px auto !important;
		margin-bottom: 0px !important;
		display: block !important;
		text-align: center !important;
		background-position: calc(50% - 80px) 20px !important;
		background-size: 80px auto !important;
		float: none !important;
		height: 140px !important;
	}
	.footerCenter{
		width: 100% !important;
		display: block !important;
		text-align: center !important;
		margin-top: 0px !important;
		height: auto !important;
	}
	.footerRight{
		display: block !important;
		width: 100% !important;
		margin-right: 0px !important;
		margin-bottom: 50px !important;
		padding-top: 0px !important;
		a{
			font-size: 0.5rem !important;
		}
	}
	#MenuIcon{
		display: block !important;
		position: absolute !important;
		top: 0px;
		right: 0px;
		border: 0px;
	}
	.navHolder{
		display: none !important;
	}
	.centerColumn .centerRight .userform .field .text, .centerColumn .centerRight .userform .field .textarea{
		min-width: 220px;
		width: 95%;
	}
	.requiredField.checkbox .middleColumn{
		width: 280px;
	}
	.leftColumn {
		display:block !important;
	}
	.centerColumn {
		display: block !important;
		.centerRight {
			position: relative !important;
			display: block !important;
			width:100% !important;
			min-width:100% !important;
			padding: 10px !important;
		}
		.centerLeft {
			display: none !important;
		}
	}
	#MobileNavigation {
		width:100%;
		right:-100%;
	}
	#SearchForm_SearchForm fieldset {
		#SearchForm_SearchForm_Search {
			font-size:30px;
			width:240px !important;
		}
		#SearchForm_SearchForm_action_results {
			width: 30px;
    		height: 30px;
    		margin-left:10px;
    		margin-right:10px;
		}
	}
}


@media all and (max-width: 450px)  {
	#SearchForm_SearchForm fieldset {
		#SearchForm_SearchForm_Search {
			font-size:30px;
		}
		#SearchForm_SearchForm_action_results {
			    width: 30px;
    			height: 30px;
    			margin-top:15px;
		}
	}
	.tacSmall {
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
}


#MobileNavigation{
	background-color: $mainColor;
	.toggleNav{
		color: $mainColor2;
	}
}
