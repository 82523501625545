/*
|===============================================================
|	Font Stack
|===============================================================
*/



@import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');

$font-stack: 'Raleway', sans-serif;
$font-heading: 'Papyrus W01';

/*
|===============================================================
|	Color Variables
|===============================================================
*/
$textLight:                 #eeeeee;
$textDark:                  #333333;
$contentColor:              #000000;
$HeadingColor:              rgb(44,59,107);
$LinkColor:                 rgb(44,59,107);
$themeColor:                rgb(162,169,173);
$themeColor2:               rgb(208,212,214);
$lightGrey:                 #c6c6c6;
$textGrey:                  #858585;
$mainColor:                 rgb(44,59,107);
$mainColor2:                rgb(198,54,43);
 

// darken( $base-color, 10% );
// lighten( $base-color, 10% );
// saturate( $base-color, 20% );
// desaturate( $base-color, 20% );
// adjust-hue( $base-color, 20% );
//

/*
|===============================================================
|	Transition Variables
|===============================================================
*/
$t1:                      all 0.35s;
$t2:                      all  0.6s;

/*
|===============================================================
|	Transform Translate Variable
|===============================================================
*/
$center: translate(-50%,-50%);

/*
|===============================================================
|	Name: border-radius
|	Desc: Add a border radius to the element
|	@example scss - border-radius(3px)
|===============================================================
*/
@mixin border-radius($radius) {
     -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
         -ms-border-radius: $radius;
             border-radius: $radius;
}

/*
|===============================================================
|	Name: box-shadow
|	Desc: Add box shadow to element
|   @param string $input
|	@example scss - box-shadow(10px 10px 5px #888888)
|===============================================================
*/
@mixin box-shadow($input) {
         -webkit-box-shadow: $input;
            -moz-box-shadow: $input;
             -ms-box-shadow: $input;
              -o-box-shadow: $input;
                 box-shadow: $input;  
}

/*
|===============================================================
|	Name: text-shadow
|	Desc: Add text shadow to element with text
|   @param string $input
|	@example scss - text-shadow(10px 10px 5px #888888)
|===============================================================
*/
@mixin text-shadow($input) {
        -webkit-text-shadow: $input;
           -moz-text-shadow: $input;
            -ms-text-shadow: $input;
             -o-text-shadow: $input;
                text-shadow: $input;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
       -moz-transition: $transition;
        -ms-transition: $transition;
            transition: $transition;
}

@mixin transform($transform) {
      -webkit-transform: $transform;
         -moz-transform: $transform;
          -ms-transform: $transform;
              transform: $transform;
}

@mixin box-sizing($box-sizing) {
    -webkit-box-sizing: $box-sizing;
       -moz-box-sizing: $box-sizing;
        -ms-box-sizing: $box-sizing;
            box-sizing: $box-sizing;
}

@mixin background-cover($image) {
        background-position: center;
		background-image:$image;
		background-size: cover;
}

@mixin filter($filter) {
    -webkit-filter: $filter;
       -moz-filter: $filter;
        -ms-filter: $filter;
            filter: $filter;
}

@mixin stretchCover($background, $position, $index) {
       background-color: $background;
                 position: $position;
                     z-index: $index;
                        height: 100%;
                         width: 100%;
                           left: 0px;
                            top: 0px;
}

@mixin clip-path($input){
             -webkit-clip-path: $input;
                -moz-clip-path: $input;
                 -ms-clip-path: $input;
                  -o-clip-path: $input;
                     clip-path: $input;
}

/*
|===============================================================
|	Name: Center El
|	Desc: Used to center elements within parent element
|	@see transform
|	@example scss - centerEl()
|===============================================================
*/

@mixin centerEl() {
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center)
}

/*
|===============================================================
|	Name: transform-origin
|	Desc: Reset transform origin of element
|   @param string $input
|	@example scss - transform-origin('top right')
|	@example scss - transform-origin('20% 50%')
|===============================================================
*/

@mixin transform-origin($input){
     -webkit-transform-origin: $input;
        -moz-transform-origin: $input;
         -ms-transform-origin: $input;
          -o-transform-origin: $input;
             transform-origin: $input;
}

/*
|===============================================================
|	Name: perspective
|	Desc: Set the perspective from where an element is viewed
|   @param string $input
|	@example scss - perspective(500px)
|===============================================================
*/

@mixin perspective($input){
          -webkit-perspective: $input;
             -moz-perspective: $input;
              -ms-perspective: $input; 
               -o-perspective: $input;
                  perspective: $input;
}
@mixin transform-style($input){ 
      -webkit-transform-style: $input;
         -moz-transform-style: $input;
          -ms-transform-style: $input;
           -o-transform-style: $input;
              transform-style: $input;
}
@mixin animation($input){
            -webkit-animation: $input;
               -moz-animation: $input;
                -ms-animation: $input;
                 -o-animation: $input;
                    animation: $input;
}
@mixin appearance($input){
             -webkit-appearance: none;
                -moz-appearance: none;
                 -ms-appearance: none;
                  -o-appearance: none;
                     appearance: none;
}

/*
|===============================================================
|	Name: flex
|	Desc: Adds Flexbox to element
|   @param string $input
|	@example scss - flex('center')
|===============================================================
*/

@mixin flex($input){
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  webkit-align-items: $input;
	  -ms-flex-align: $input;
	  align-items: $input;
	  -webkit-justify-content: $input;
	  -ms-flex-pack: $input;
	  justify-content: $input;  
}

@mixin bezier-transform($input) {
  -webkit-transition-timing-function: $input;
  -moz-transition-timing-function: $input;
  -ms-transition-timing-function: $input;
  transition-timing-function: $input;
}

/*
|===============================================================
|	Responsive
|===============================================================
*/

$break-small: 767px;
$break-medium: 991px;
$break-large: 1024px;

/*
|===============================================================
|	Name: breakpoint
|	Desc: Adds responsive breakpoints to the website
|   @param string $media - Device / Can also been a pixel value
|   @param string $max - If min is pixel value, then add max value
|	@example scss - flex('center')
|===============================================================
*/

@mixin breakpoint($media, $max: '1199px') {
	@if $media == mobile {
		@media only screen and (max-width: $break-small) { @content; }
	}
	@else if $media == toTablet {
		@media only screen and (max-width: $break-medium) { @content; }
	}
	@else if $media == toDesktop {
		@media only screen and (min-width: $break-large) { @content; }
	}
	@else {
		@media only screen and (min-width: $media) and (max-width: $max) { @content; }
	}
}

.visible {
	display: block !important;
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
     -moz-box-flex: $values;
      -webkit-flex: $values;
      -ms-flex: $values;
          flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
     -moz-flex-flow: $flow;
      -ms-flex-flow: $flow;
          flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
     -moz-box-ordinal-group: $val;     
         -ms-flex-order: $val;     
          -webkit-order: $val;  
            order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
     -moz-flex-grow: $grow;
      -ms-flex-grow: $grow;
          flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
     -moz-flex-shrink: $shrink;
      -ms-flex-shrink: $shrink;
          flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
     -moz-flex-basis: $width;
      -ms-flex-basis: $width;
          flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($align) {
  -webkit-align-self: $align;
     -moz-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}
