#tickerContainer {
	clear: both;
	position: relative;
	bottom: 0;
	left:0px;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 46px;
}

.tickertape {
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section, .tickertape span {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight: lighter;
}

.tickertape a {
	color: #fff;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight: lighter;
}

.tickertape section {
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 14px;
	z-index:999
}

.ticker {
	position: relative;
	max-height: 46px;
	width: 100%;
	background-color: rgba(79,79,79,0.9);
	z-index:4999;
	bottom:200px;
	#LatestNews {
		position: absolute;
	    left: 0px;
	    width:180px;
	    padding-left: 10px;
	    height: 46px;
	    color: #fff;
	    z-index: 9999;
	    line-height: 45px;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding-right: 20px;
	}
}
