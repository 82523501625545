// ================ Housepoints Wrapper ======================
.housepointsWrapper {
	width:100%;
	text-align:center;
	.housepointsInner {
		width:100%;
		max-width:1280px;
		margin:0 auto;
	   
    	text-align: center;
		.housepointsItem {
			width:200px;
			display:inline-block;
			margin:20px;
			background-position:center center;
			background-size:cover;
			background-repeat:no-repeat;
			padding:30px;
			.housepointsIcon {
				width:100%;
			}
		}
	}
}

// ================ Housepoints Box ======================
.HouseBox {
	display:block;
	width:100%;
	padding:30px;
	border:2px solid $LinkColor;
	margin-bottom:60px;
	display:flex;
	.houseLogo {
		width:250px;
		height:250px;
		background-size:contain;
		background-position: center center;
		background-repeat:no-repeat;
		margin-right:30px;
		display:inline-block;
	}
	.houseInfo {
		width:calc(100% - 280px);
		h1 {
			margin-bottom:10px;
		}
		.pointsBox {
			margin-bottom:40px;
			.pointsBar {
				height:10px;
			}
		}
		.attendanceBox {
			margin-bottom:40px;
			.pointsBar {
				height:10px;
			}
		}
	}
}
.ClassPersonBox {
	display:block;
	width:100%;
	padding:30px;
	border:2px solid $LinkColor;
	margin-bottom:60px;
	display:flex;
	.classPersonImage {
		width:250px;
		height:250px;
		background-size:contain;
		background-position: center center;
		background-repeat:no-repeat;
		margin-right:30px;
		display:inline-block;
	}
	.classPersonInfo {
		width:calc(100% - 280px);
		h1 {
			margin-bottom:10px;
		}
	}
}
