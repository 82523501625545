/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload {
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	padding: 12px 25px 12px 30px;
	margin-bottom: 5px;
	background-color: rgba(44,59,107,0.5);
	@include transition($t1);
	text-align: left;
	.uploadTitle {
		font-size: 0.9rem;
	    display: inline-block;
	    text-align: left;
	    color: $mainColor;
	}
	a {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon {
	    display: inline-block;
	    float: right;
	    width: 20px;
	    height: 20px;
	    background-image: url(../images/download.png);
	    background-position: center;
	    background-size: contain;
	    background-repeat: no-repeat;
	    span{
	    	display: none;
	    }
	}
	&:hover {
		background-color: $mainColor  !important;
		.uploadTitle {
			color: #FFF !important;
		}
		.fileIcon {
		    background-image: url(../images/download_white.png);
		}
	}
}

.newsletterlinks .upload{
	max-width: 100%;
	background-color: #FFF;
}