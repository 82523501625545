/*
|===============================================================
|	News Comments Form Styling
|===============================================================
*/

.CommentFormArea {
	padding: 20px 0px 0px 0px;
	form {
		padding-bottom: 20px;
	}
	input[type=text], input[type=email], input[type=password], textarea { 
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label {
		font-weight: lighter;
	}

	input[type=submit] {
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff;
		    background-color: #17aae4;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transform($t1);
		    &:hover {
		    	background-color: #2F3B67 !important;
		    }
	}

	#ForgotPassword {
		margin-top: 20px;
		a {
			font-weight: lighter;
			color: #389FEC;
		}
	}
}
