.translate{
    cursor: pointer;
    height: 100%;
    width: 50px;
    margin-right: 20px;
    background-image: url(../images/translate.png);
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
}
.TranslateBG{
    display: none;
    position: fixed;
    top: 0px;
    z-index: 9998;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.85);
}

#google_translate_element{
    display: none;
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    @include transform($center);
}