#usefulLinksWrapper{
	width: 140px;
	position: absolute;
	top: 100px;
	right: 20px;
	#usefullinkstoggle{
		cursor: pointer;
		background-color: $mainColor2;
		color: #FFF;
		font-weight: 100;
		padding: 10px;
		padding-top: 0px;
		text-align: center;
		&::after{
			content: ' \02c7';
			font-size: 1.5rem;
			position: relative;
			top: 10px;
			left: 3px;
		}
	}
	#usefulLinks {
		width: 100%;
		display: none;
		ul{
			padding: 0px;
			margin: 0px;
			li {
				width: 100%;
				display: inline-block;
				align-items: center;
				text-align:center;
				background: $themeColor;
				color: white;
				font-weight: 100;
				margin-bottom: 5px;
				.titleText {
					display: block;
					color: $mainColor;
					line-height: 38px;
					text-align: left;
					padding-left: 35px;
				}
				.homepageQLIconWrapper{
					background-color: $mainColor;
					width: 30px;
					height: 40px;
					padding: 5px;
					float: left;
					display: inline-block;
					.homepageQLIcon {
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						display: inline-block;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}