/*
|===============================================================
|	Google Maps Canvas
|   Comments: 
|   1. Address box is used on all standard sites. 
| 	   This is conditioned to show on all FSE Builds.
|===============================================================
*/

#gmap_canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

}
.mapBox {
	position: relative;
	width: calc(100% - 100px);
	padding: 50px;
	min-height:500px;

	.address { // [1]
		position: relative;
		background: rgba(255,255,255,0.8);
		padding: 80px;
		display: inline-block;
		z-index: 4000;
		ul {
			padding-left: 0;
			margin: 0;
			padding-right: 20px;
			li {
				list-style: none;
				margin-bottom: 5px;
				h3, i, p span {
					color: $mainColor2;
				}
				h3 {
					margin-top: 0px;
					font-weight: 100;
					span {
						font-weight: 700;
					}
				}
				p {
					color: $mainColor;
					font-size: 22px;
					margin-bottom: 0;
					font-weight: 100;
				}
				i {
					display: inline-block;
				}
			}
		}
		li.contact {
			margin-top: 40px;
		}
	}
}
