/*
|===============================================================
|	Homepage News Widget
|===============================================================
*/

.homepageNewsSliderWrapper {
	background-color: #FFFFFF;
	padding:60px 30px;
	position:relative;
	@include transition($t1);
	.titleWrapper {
		max-width:1280px;
		margin:0 auto;
		position: relative;
		h1 {
			text-align: left;
			color: $HeadingColor;
			display: inline-block;
			margin-bottom:20px;
		}
	}
	.viewMoreBtn {
		padding:10px 30px;
		background-color:red;
		border-radius:10px;
		color:$LinkColor;
		display:inline-block;
		font-weight:bold;
		@include transition($t1);
		&:hover {
			background-color:$LinkColor;
			color:#FFFFFF;
		}
	}
	.outer {
		max-width:1280px;
		margin:0px auto 30px auto;
		.homepageNewsSlider {
			width:100%;
			/**/
			margin:0 auto;
			display:flex;
			z-index:200;
			.slide {
				z-index:1;
				width:300px;
				height:572px;
				background-color:#FFFFFF;
				.inner {
					/*margin:20px;*/
					border:2px solid $lightGrey;
					padding:40px 20px;
					height:100%;
					position:relative;
					.articleDate {
						color:#000000;
						padding:10px 0 20px 0;
						font-size:14px;
						border-top:1px solid $LinkColor;
						font-weight:700;
					}
					.title {
						color:#000000;
						font-size:24px;
						height:150px;

					}
					.content {
						margin-bottom:60px;
						
					}
					.readMore {
						border:1px solid $LinkColor;
						padding:10px 30px;
						display:inline-block;
						margin:30px 0 0 0;
						@include transition($t1);
						position:absolute;
						left:20px;
						bottom:30px;
						.italic {
							font-style: italic;
						}
						&:hover {
							background-color:$LinkColor;
						}
					}
				}
				section {
					margin-bottom:60px;
					p {
						text-align:justify;
					}
				}
				
			}
		}
	}
	.widgetNewsPrev, .widgetNewsNext {
		position: absolute;
		top:10px;
		display:inline-block;
		width:40px;
		height:40px;
		text-align:center;
		border:1px solid $lightGrey;
		z-index:8;
		background-size: 50% 50%;
		background-position: center center;
		background-repeat: no-repeat;
		@include transition($t1);
		a {
			color:$LinkColor;
			width: 100%;
		    height: 100%;
		    display: block;
		}
		&:hover {
			background-color:$LinkColor;
			border:1px solid $LinkColor;
		}
	}
	.widgetNewsPrev {
		right:70px;
		background-image: url(../images/leftBracketBlack.svg);
	}
	.widgetNewsNext {
		right:0px;
		background-image: url(../images/rightBracketBlack.svg);
	}
	&.hidden {
		opacity:0;
	}
}
