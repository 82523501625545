#breadcrumbs {
	background-color: $mainColor2;
	padding: 3px 0;
	padding-right: 20px;
	text-align: right;
	p {
		margin-bottom: 0px;
		font-size: 0.9rem;
		color: #FFF;
		text-transform: uppercase;
		a{
			font-size: 0.9rem;
			color: #FFF;
			text-transform: uppercase;
		}
	}
}