.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.LightBox {
	a {
		text-decoration: none !important;
	}
	.staffItem {
		width:290px;
		height:290px;
		margin:0 20px 20px 0;
		position: relative;
		display: inline-block;
		
		&:after {
			content:'';
			position: absolute;
			left:50%;
			top:50%;
			@include transform(translateX(-50%) translateY(-50%));
			width:0%;
			height:0%;
			background-color:rgba($LinkColor, 0.5);
			z-index:2;
			@include transition($t1);

		}
		.staffImage {
			position: absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center center;
			
			@include transition($t1);
			@include filter(grayscale(100%));
		}
		.title {
			position: absolute;
			left:50%;
			top:50%;
			@include transform(translateX(-50%) translateY(-50%) scale(0.7, 0.7));
			opacity:0;
			width:90%;
			@include transition($t1);
			text-align:center;
			z-index:3;
			color:#FFFFFF;
		}
		&:hover {
			.staffImage {
				background-size: 140% auto;
			}
			
			.title {
				@include transform(translateX(-50%) translateY(-50%) scale(1, 1));
				opacity:1;
			}
			&:after {
				width:100%;
				height:100%;
			}
		}
	}
}