#welcomeMessageWrapper{
	background-image: url(../images/Lion_red1.png), url(../images/Lion_red2.png);
	background-position: -85px 0px, calc(100% + 85px) 0px;
	background-repeat: no-repeat, no-repeat;
	background-size: contain, contain;
	#welcomeMessage {
		width: 50%;
		margin: 0px auto;
		padding-top: 35px;
		padding-bottom: 70px;
		text-align: center;
		h1{
			position: relative;
			top: 20px;
			margin: 0px auto;
			line-height: 80px;
			&::after{
				width: 200px !important;
				display: block !important;
				top: -30px !important;
				margin: 20px auto;
				left: 0% !important;
			}
		}
		div{
			padding-top: 50px;
			p{
				padding-left: 40px;
				font-size: 1em;
				text-align: justify;
			}
		}
	}
}