#quickLinks {
	display: block;
	position: relative;
	z-index: 2000;
	overflow: hidden;
	width:100%;
	ul{
		position: relative;
	}
	li {
		height: 200px;
		align-items: center;
		background-position: center center;
		background-size: cover;
		position: relative;
		color: white;
		font-weight: 100;
		display: inline-block;
		opacity:1;
		@include transition($t1);
		    
		a {
			display:block;
			width:100%;
			height:100%;
			@include transition($t1);
			font-size:20px;
			.titleText {
				position: absolute;
			    display: inline-block;
			    left: 10%;
			    bottom: 20%;
			    color:#FFFFFF;
			    @include transition($t1);
			    .yellow {
			    	color:$LinkColor;
			    }
			    &:after{
			    	content:'';
			    	position: absolute;
			    	bottom:-10px;
			    	left:0;
			    	height:1px;
			    	width:0;
			    	background-color:$LinkColor;
			    	@include transition($t1);
			    }
			}
			&:hover {
				background-color:rgba(0,0,0,0.4);
				.titleText {
					bottom:calc(50% - 15px);
					font-size:24px;
					&:after{
						width:80px;
					}
				}
				.findOutMore {
					bottom:calc(50% - 60px);
				}
			}
			.findOutMore {
				position: absolute;
				left:10%;
				bottom:-40px;
				color:#FFFFFF;
				font-size:15px;
				@include transition($t1);
			}
		}
		&.happyTop {
			@include transform(translateY(0%) !important);
			opacity:1 !important;
		}
	}
}
