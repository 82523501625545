/*** Mobile Screens ***/
#sidebar{
  position: relative;
  width: 100%;
  padding: 20px;
  padding-bottom: 220px;
  margin-bottom: 40px;
  float: right;
  position: relative;
  background-color: $themeColor2;
  background-image: url(../images/Lion.png);
  background-size: 204px 200px;
  background-position: bottom right;
  background-repeat: no-repeat;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $mainColor2;
    clear: both;
  }
  .current{
    &::before{
      content: ' \203A ';
      color: $mainColor2;
      font-weight: bold;
      position: absolute;
      left: 15px;
      top: 4px;
    }
    span{
      font-weight: bold;
      position: relative;
    }
  }
  .Level-1{
    display: none;
    ul{
      display: none;
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $mainColor;
    &:hover{
      color: $mainColor;
    }
  }
  .sidebar-heading{
    display: block;
    /*float: left;*/
    clear: both;
    /*width: 80%;*/
    a{
      padding: 5px 30px 5px 10px;
      display: block;
      text-decoration: none;
      color: $mainColor;

    }
  }
  ul{
    padding:0;
    margin:0;
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
          margin-left: 20px !important;
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
            margin-left: 30px !important;
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      margin:0;
      padding:0;
      list-style-type: none;
      a{
        display: block;
        padding: 5px 30px 5px 10px;
        text-decoration: none;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);
        span{
          color: $mainColor;
          @include transition($t1);
          padding-left: 20px;
          display: inline-block;
        }
        &:hover span{
          color: $mainColor !important;
        }
        &:hover{
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (min-width: 600px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
