/**
 * This typography file is included in the WYSIWYG editor
 */
 
@font-face{
    font-family:"Papyrus W01";
    src:url("../fonts/Fonts/883327/c739b5f7-8f98-4f2c-9990-779284408903.eot?#iefix");
    src:url("../fonts/Fonts/883327/c739b5f7-8f98-4f2c-9990-779284408903.eot?#iefix") format("eot"),url("../fonts/Fonts/883327/01fb9a9b-55ce-4693-b692-bd99b3c70400.woff2") format("woff2"),url("../fonts/Fonts/883327/2fe0bd0f-bd5a-4ed4-8c63-1876362fdf8c.woff") format("woff"),url("../fonts/Fonts/883327/0b0b385a-f6e8-4a33-887f-2f178a576139.ttf") format("truetype");
}

.typography * {
	color: $textDark;
}
.typography blockquote {
	margin-left:20px;
}
.typography hr {
	padding:5px;
}
p,
ul li,
ol li,
a {
	line-height: 1.3rem;
	font-size: 1rem;
	font-weight: 100;
}
p {
	margin-bottom:20px;
	display:block;
	overflow:hidden;
	img {
		/*float:none !important;*/
		vertical-align:top;
	}
}


/** CMS LIST STYLES **/
.typography ul,
.typography ol {
	margin-left:10px;
	margin-bottom:20px;
}
	.typography ul li,
	.typography ol li {
		margin:4px 10px;
		line-height:1.4em;
	}
		.typography ul li {
		 list-style-type:disc;
		}
		.typography ol li {
			list-style-type:decimal;
    }
/* HEADER STYLES */

h1,
h2,
h3,
h4 {
	margin-bottom:14px;
	color: $HeadingColor;
}

h1{
	font-family: $font-heading;
	color: $HeadingColor;
}
h2 {
	text-transform: uppercase;
}
h3,
h4 {
	font-weight:normal;
}
	h1 {
		font-size: 2.4rem;
	}
	h2 {
		font-size: 1.3rem;
	}
	h3 {
		font-size:1.2rem;
	}
	h4 {
		font-size:1.1rem;
	}

h1{
	display: inline-block;
	margin-bottom: -20px !important;
	&::after{
		content: " ";
		background-color: $mainColor2;
		height: 2px;
		width: 60%;
		display: inline-block;
		position: relative;
		top: -30px;
		left: 20%;
	}
}

/* LINK STYLES */
.typography a {
	color: $LinkColor;
  	font-size:1rem;
  	text-decoration:none;
}
	.typography a:hover {
		text-decoration:underline;
	}
	
	
.typography table {
	font-size:1.1rem;
}
	.typography table tr td {
		padding:3px 5px;
	}

	
/* WYSIWYG EDITOR ALIGNMENT CLASSES */
.typography .left {
	text-align:left;
}
.typography .center {
	text-align:center;
}
.typography img.center {
	display: inline-block;	
}
.typography .right {
	text-align:right;
}
.typography img.right {
	float:right;
}
.typography img.left {
	float:left;
}
.typography img {
	max-width: 100%;	
}
.typography p.MsoNormal, .typography p.MsoBodyText {
	margin: 0;
}

@media all and (max-width: 760px)  {
/* Force table to not be like tables anymore */
.typography table.largeTable, .typography .largeTable thead, .typography .largeTable tbody, .typography .largeTable th, .typography .largeTable td, .typography .largeTable tr { 
	display: block; 
}

.typography table.smallTable, .typography .smallTable thead, .typography .smallTable tbody, .typography .smallTable th, .typography .smallTable td, .typography .smallTable tr { 
	display: block; 
}

.typography table.largeTable, .typography table.smallTable {
	height: auto !important;
	width: auto !important;	
	text-align: left;
}

/* Hide table headers (but not display: none;, for accessibility) */
.typography .largeTable tr.firstRow { 
	position: absolute;
	top: -9999px;
	left: -9999px;
}


.typography .largeTable tr, .typography .smallTable tr { border: 1px solid #ccc; }

.typography .largeTable tr:nth-of-type(odd), .typography .smallTable tr:nth-of-type(odd) { 
  background: #eee; 
}

.typography .largeTable td, .typography .smallTable td { 
	/* Behave  like a "row" */
	border: none;
	border-bottom: 1px solid #eee; 
	position: relative;
	padding-left: 25%; 
	width: 100%;
}

.typography .largeTable td:before { 
	/* Now like a table header */
	position: absolute;
	/* Top/left values mimic padding */
	content: attr(data-title);
	top: 6px;
	left: 6px;
	width: 20%; 
	padding-right: 10px; 
}	
}