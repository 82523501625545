#blackout, .blackout, .blackoutTranslate {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 5000;
    background-color: rgba(0, 0, 0, 0.85);
}
.noticesBlackout {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(42, 49, 115, 0.85);
}

