.centerColumn {
	.centerLeft {
		.contentWrapper {
			margin-bottom:60px;
		}
		.NewsletterItem {
			width:250px;
			border:2px solid $LinkColor;
			padding:20px;
			display:inline-block;
			margin:0 20px 20px 0;
			@include transition($t1);
			.newsletterThumb {
				width:100%;
				height:280px;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
			}
			.newsletterTitle {
				width: 100%;
				display:block;
				text-align:center;
			}
			&:hover {
				background-color:$LinkColor;
			}
		}
	}
	
}