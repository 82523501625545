/*
|===============================================================
|	Video Page
|===============================================================
*/

.videoHeader {
	height: 126px;
	background-color: #000;
}
.fullScreenBlackout {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer {
	padding-bottom: 20px;
	overflow: hidden;
}
.video_background {
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
}
.active {
	.closeVideo {
		display: block !important;
	}
}


.videoBlackout {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	@include transition($t1);
	height: 100%;
	background-color: rgba(0,0,0, 0.6);
}

.videoContainer:hover {
	.videoBlackout {
		background-color: rgba(0,0,0, 0.4) !important;
	}
}

.videoTitle {
	 font-family: geomanistblack;
	 font-size: 2.8em;
	 color: #fff !important;
	 margin-bottom: 20px;
	 text-align: center;
}
.mejs__container * {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.closeVideo {
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i {
		color: #fff;
	}
}

.watchFull {
	cursor: pointer;
	position: absolute;
    left: 50%;
    top: 50%;
    @include transform($center);
}

.VideoCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}

.videoWrapper {
	padding-bottom: 50% !important;
	width: 100% !important;
}
@media all and (max-width: 860px) {
	.videoContainer {
		padding-bottom: 50%;
		width: 100%;
	}
	.videoTitle {
		font-size: 1.8em !important;
	}
}

body.oh {
	overflow: hidden !important;
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	max-height: 100vh;
}

.oh {
	overflow: hidden !important;
}
/*** Video Page ***/
